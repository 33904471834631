<template>
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-exclamation-triangle"></i> สาเหตุการยกเลิก </h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body">
          <page-order-cancel-reason/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import  pageOrderCancelReason from "./view/page.order_cancel_reason.vue";

  export default {
    name: 'order_cancel_reason-index',
    components: {
      pageOrderCancelReason
    }
  };
</script>
<style>

</style>
